import { useState, useEffect } from 'react';
import axios from 'axios';

function App() {
  const [value, setValue] = useState('');
  const [tasks, setTasks] = useState([]); // Estado para almacenar la lista de tareas

  // Configura la URL de la API desde las variables de entorno
  const apiUrl = process.env.REACT_APP_API_BASE_URL || 'http://127.0.0.1:8000/api/Tareas';

  // Obtener tareas al cargar la página
  useEffect(() => {
    axios.get(apiUrl)
      .then(response => {
        setTasks(response.data.data); // Ajusta para tomar los datos de la respuesta
      })
      .catch(error => {
        console.error('Hubo un error al obtener las tareas:', error);
      });
  }, []); // Esto se ejecutará solo una vez, al montar el componente

  const handleOnChange = (e) => {
    setValue(e.target.value);
  };

  const handleAddTask = () => {
    if (value.trim()) { // Verifica que no sea una tarea vacía
      const newTask = { detalle: value, estados_id: 1 }; // Crea la nueva tarea (ajusta campos según tu API)

      axios.post(apiUrl, newTask)
        .then(response => {
          setTasks([...tasks, response.data.tareas]); // Añadir la tarea que la API ha creado
          setValue(''); // Limpia el campo de input después de agregar la tarea
        })
        .catch(error => {
          console.error('Hubo un error al agregar la tarea:', error);
        });
    }
  };

  const handleDeleteTask = (id) => {
    axios.delete(`${apiUrl}/${id}`)
      .then(response => {
        setTasks(tasks.filter(task => task.id !== id)); // Elimina la tarea localmente
      })
      .catch(error => {
        console.error('Hubo un error al eliminar la tarea:', error);
      });
  };

  return (
    <div className="container mt-4">
      <h1 className="mb-4">Lista de Tareas</h1>
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          value={value}
          onChange={handleOnChange}
          placeholder="Escriba La Tarea"
        />
        <button
          className="btn btn-primary"
          onClick={handleAddTask}
        >
          Agregar tarea
        </button>
      </div>
      <ul className="list-group">
        {tasks.map((task) => (
          <li key={task.id} className="list-group-item d-flex justify-content-between align-items-center">
            {task.detalle} {/* Asegúrate de que este campo coincida con tu respuesta de la API */}
            <button
              className="btn btn-danger btn-sm"
              onClick={() => handleDeleteTask(task.id)}
            >
              Eliminar
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default App;
